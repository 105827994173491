<!--
* @Description:
-->
<template>
  <div>
    <!--主体为swiper标签-->
    <!--属性 :options 绑定的是data中设置的swiper配置项-->
    <!--属性 ref 用于获取该dom元素，在计算属性computed中将被调用-->
    <!--属性 id 为swiper最外层容器设置css样式-->
    <div v-show='pxbcList.length>0'>
      <swiper ref="mySwiper" class="swiper" :options="swiperOption">
        <swiper-slide  v-for="(item,index) in pxbcList" :key="index" @click.native="jumpCourseInfo(item)">
          <div class='swiper-slide-content'>
            <div class='content-img'>
              <div class='textStr' v-show="!item.courseCover_url">{{item.courseTitle}}</div>
              <img :src="courseCover_url(item)" alt="">
            </div>
            <div class='content-info'>
              <div class='content-info-title'>{{item.courseTitle}}</div>
            </div>
          </div>
        </swiper-slide>
        <!-- 可选的控件 -->
        <!--分页器-->
        <!-- <div class="swiper-pagination" slot="pagination"></div> -->
        <!--前进后退按钮-->
        <div class="swiper-button-prev" slot="button-prev"></div>
        <div class="swiper-button-next" slot="button-next"></div>
      </swiper>
    </div>
    <!--配置自定义的页面跳转按钮，to(page)为自定义方法，其内调用了swiper的内置方法-->
    <emptyData v-show='pxbcList.length==0'></emptyData>
  </div>
</template>

<script>
import emptyData from '@/components/emptyData.vue'
export default {
  name: '',
  watch: {},
  components: {
    emptyData
  },
  data () {
    return {
      // 配置项例子
      swiperOption: {
        slidesPerView: 3,
        spaceBetween: 30,
        centeredSlides: true,
        clickable: true,
        loop: true,
        // autoplay: {
        //   // swiper手动滑动之后自动轮播失效的解决方法,包括触碰，拖动，点击pagination,重新启动自动播放
        //   disableOnInteraction: false,
        //   // 自动播放时间：毫秒
        //   delay: 2000,
        // },
        // pagination: {
        //   el: '.swiper-pagination',
        //   clickable: true,
        //   // bulletClass: "my-bullet",
        // },
        // 前进后退按钮
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
      },
      pxbcList: []
    }
  },
  methods: {
    courseCover_url (item) {
      let localCoverUrl = item.courseCover_url ? item.courseCover_url : require('../../../assets/tuanBg.png')
      return localCoverUrl
    },
    onSwiper (swiper) {
      console.log(swiper)
    },
    onSlideChange () {
      console.log('slide change')
    },
    jumpCourseInfo (item) {
      this.$axios.get(this.$apiUrl.loadCourseDetail, {
        params: {
          courseId: item.id
        }
      }).then(res => {
        if (res.data.success) {
          this.$router.push(`/courseInfo/${item.id}`)
        }
      })
    },
    // 查询培训班次
    getListPxbc () {
      this.$axios.post(this.$apiUrl.listPxbc, {}).then(res => {
        console.log(res)
        if (res.data.success) {
          this.pxbcList = JSON.parse(JSON.stringify(res.data.data))
        }
      })
    },
  },
  mounted () {
    this.getListPxbc()
  },
}
</script>

<style scoped lang="scss">
/* <!--容器样式--> */
::v-deep .swiper-wrapper {
  padding: 50px 0px;
  width: 921px;
}
/* <!--幻灯片样式--> */
::v-deep .swiper-slide {
  height: 366px;
  width: 320px;
  background-color: aliceblue;
  text-align: center;
  background: #eee;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 300ms;
  transform: scale(0.7);
  background: #FFFFFF;
  .swiper-slide-content{
    width: 100%;
    height: 366px;
    box-shadow: 0px 7px 38px 1px #9E9E9E;
    border-radius: 10px 10px 10px 10px;
    .content-img{
        width: 100%;
        height: 210px;
        position: relative;
        /* background-repeat: no-repeat;
        background-size: 100% 100%; */
        img{
          position: absolute;
          left: 0px;
          top: 0px;
            width: 100%;
            height: 210px;
            left: 0px;
            top: 0px;
        }
        .textStr{
          width: 100%;
          font-size: 30px;
          font-family: STKaiti-Regular, STKaiti;
            //display: flex;
            height: 100%;
            //flex-wrap: wrap;
            padding: 0px 10px;
            //justify-content: center;
            //align-content: center;
            color: #ffff;
            line-height: 210px;
            position: absolute;
            z-index: 9999999;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2 ;
            -webkit-box-orient: vertical;
        }
    }
    .content-info{
        padding: 24px;
        text-align: left;
        .content-info-title{
            font-size: 22px;
            margin-bottom: 22px;
            color: #525455;
            font-family: Microsoft YaHei-Bold, Microsoft YaHei;
            font-weight: bold;
            color: #000000;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2 ;
            -webkit-box-orient: vertical;
        }
        .content-info-courseNum{
            font-size: 14px;
            font-family: Microsoft YaHei-Regular, Microsoft YaHei;
            font-weight: 400;
            color: #525455;
        }
    }
  }
}
.swiper-slide-active,
.swiper-slide-duplicate-active {
  transform: scale(1);
}

.swiper-button-prev {
  background-image: url("../../../assets/image/homeImg/2(1).png");
  background-size: cover;
  width: 60px;
  height: 60px;
  border-radius: 7px 7px 7px 7px;
}
.swiper-button-next {
  background-image: url("../../../assets/image/homeImg/2.png");
  background-size: cover;
  width: 60px;
  height: 60px;
  border-radius: 7px 7px 7px 7px;
}
</style>
