<template>
  <div class="homePageWrap container">
    <header>
      <el-row class="hedaer-Bg" type="flex">
        <el-col :span="12">
          <div class="grid-content header-left-content">
            <div class="logo-wrap">
              <img src="../../assets/image/homeImg/logo.png" />
            </div>
            <div class="app-title" @click="$router.push('/home')">
              职业卫生在线培训
            </div>
          </div>
        </el-col>
        <el-col :span="12">
          <div class="grid-content header-right-content">
            <div @click="pageJumpLogin" v-if="userName == ''" style='cursor:pointer'>登录</div>
            <div v-else class="userName-wrap">
              <el-dropdown placement="bottom">
                <span class="el-dropdown-link">
                  学员{{ userName
                  }}<i class="el-icon-arrow-down el-icon--right"></i>
                </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item @click.native="$router.push('/accountSafe')">账号安全</el-dropdown-item>
                  <el-dropdown-item @click.native="$router.push('/login')">退出登录</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </div>
          </div>
        </el-col>
      </el-row>
    </header>
    <el-main class="main-content">
      <div class="banner">
        <!-- <img src="../../assets/image/homeImg/banner.png" /> -->
        <el-carousel trigger="click" height="513"  :interval="5000" arrow="hover">
            <el-carousel-item v-for="(item,index) in bannerList" :key="index">
              <div>
                <img :src="item" style="width:100%;height:513px"/>
              </div>
            </el-carousel-item>
        </el-carousel>
      </div>
      <div class="nav_side">
        <nav>
          <router-link active-class="active" to="/home">首页</router-link>
          <router-link active-class="active" to="/trainingClass">课程中心</router-link>
          <router-link active-class="active" to="/studyCenter">学习中心</router-link>
          <router-link active-class="active" to="/examCenter">考试中心</router-link>
<!--          <router-link active-class="active" to="/newcomerPost">新人驿站</router-link>-->
<!--          <router-link active-class="active" to="/liveRoom">直播大厅</router-link>-->
<!--          <router-link active-class="active" to="/boutiqueCourse">精品课程</router-link>-->
          <router-link active-class="active" to="/trainDynamic">培训动态</router-link>
          <router-link active-class="active" to="/helpCenter">帮助中心</router-link>
        </nav>
      </div>
      <div class="content-wrap">
        <!-- 公告栏 -->
        <div class="ggl-content">
          <div class="title">公告栏</div>
          <div class="splitRow splitRow1"></div>
          <div class="splitRow splitRow2"></div>
          <div class="ggl-content-main">
            <el-row>
              <el-col :span="6" class='left-title'>
                <div>
                  <div>
                    <img src="../../assets/image/homeImg/left-progress.png" />
                  </div>
                  <div class='titleStr'>培训动态</div>
                  <div>
                    <img src="../../assets/image/homeImg/right-progress.png" />
                  </div>
                </div>
              </el-col>
              <el-col :span="18" class='right-list-wrap'>
                <div>
                  <ul class="list-ul">
                    <li v-for="(item,index) in pxdtList" :key="index" @click="$router.push(`/trainDynamicInfo/${item.id}`)">
                      <span class="icon"></span>
                      <span class="list-item">{{item.title}}</span>
                      <span class="date">{{item.createTime}}</span>
                    </li>
                  </ul>
                </div>
              </el-col>
            </el-row>
          </div>
        </div>
        <!-- 培训班次 -->
        <div class="pxbc-content">
          <div class="header-wrap">
            <div class="title">
              <div>课程中心</div>
            </div>
            <div class="more-link" @click="$router.push('/trainingClass')">
              <div>更多</div>
              <div>
                <img src="../../assets/image/homeImg/right-progress.png" />
              </div>
            </div>
          </div>
          <div class="splitRow splitRow1"></div>
          <div class="splitRow splitRow2"></div>
          <div class="ggl-content-main">
            <swiper></swiper>
          </div>
        </div>
        <!-- 精品课程 -->
<!--        <jpkcContent></jpkcContent>-->
      </div>
    </el-main>
    <footer-com></footer-com>
  </div>
</template>

<script>
import swiper from './component/swiper'
import footerCom from '@/components/footer.vue'
import jpkcContent from './component/jpkc'
export default {
  name: 'homePage',
  components: {
    swiper,
    footerCom,
    jpkcContent,
  },
  props: {},
  watch: {},
  data () {
    return {
      activeIndex: '1',
      userName: '',
      bannerList: [],
      pxdtList: [],
      pxbcList: [],
      appSecret: '83426e92f4c24390a33e0733ab7de833',
      appId: 'gi6keuezit',
    }
  },
  methods: {
    pageJumpLogin () {
      this.$router.push('/login')
    },
    handleSelect (key, keyPath) {
      console.log(key, keyPath)
    },
    jumpJpkcList () {
      this.$router.push('/boutiqueCourse')
    },
    // 获取local中用户详细信息
    getUserInfo () {
      let userinfo = localStorage.getItem('studentInfo')
        ? JSON.parse(localStorage.getItem('studentInfo'))
        : ''
      console.log(userinfo)
      if (userinfo) {
        let {stuName} = userinfo
        this.userName = stuName
      }
    },
    getBanner () {
      console.log(this.$apiUrl.listBanner)
      this.$axios.get(this.$apiUrl.listBanner, {
        params: {}
      }).then(res => {
        console.log(res)
        if (res.data.success) {
          this.bannerList = []
          res.data.data.forEach(item => {
            this.bannerList.push(item.image_url)
          })
        }
      })
    },
    getlistArticlePxdt () {
      console.log(this.$apiUrl.listArticlePxdt)
      this.$axios.post(this.$apiUrl.listArticlePxdt, {}).then(res => {
        console.log(res)
        if (res.data.success) {
          this.pxdtList = JSON.parse(JSON.stringify(res.data.data))
        }
      })
    },
  },
  mounted () {
    localStorage.setItem('appId', JSON.stringify(this.appId))
    localStorage.setItem('appSecret', JSON.stringify(this.appSecret))
    this.getUserInfo()
    this.getlistArticlePxdt()
    this.getBanner()
  },
}
</script>

<style scoped lang="scss">
  .container {
    position: relative;
    min-height: 100vh;
    background-color: #fbfbff;

    .hedaer-Bg {
      /* background-color:#6a8dea; */
      background: url("../../assets/image/homeImg/headerBg.png") no-repeat;
      background-size: 100% 100%;
      height: 56px;
      width: 100%;
      line-height: 56px;
      padding: 0 100px;

      .header-left-content {
        font-size: 22px;
        color: white;
        font-weight: 800;
        display: flex;
        justify-content: start;

        .app-title {
          cursor: pointer;
        }

        .logo-wrap {
          padding: 0 10px;
          display: flex;
          justify-content: center;
          align-items: center;

          img {
            height: 45px;
          }
        }
      }

      .header-right-content {
        font-size: 18px;
        color: white;
        height: 56px;
        display: flex;
        justify-content: end;
        align-items: center;

        .userName-wrap {
          height: 34px;
          line-height: 34px;
          align-items: center;
          display: flex;
          justify-content: center;
        }

        ::v-deep .el-dropdown {
          font-size: 18px;
          color: #4774df;
          background-color: #fff;
          height: 34px;
          line-height: 34px;
          border-radius: 5px 5px 5px 5px;
          opacity: 1;
          padding: 0px 10px;
        }
      }
    }
  }

  .nav_side {
    padding-top: 30px;
    margin-bottom: 30px;
    nav {
      display: flex;
      align-items: center;
      justify-content: space-around;
      font-size: 22px;
      font-weight: bold;
    }

    nav a {
      text-decoration: none;
      color: #000000;
      font-size: 20px;
      font-weight: normal;
      opacity: 0.9;
    }

    nav a:hover {
      opacity: 1;
    }

    .active {
      color: #608bd2;
      pointer-events: none;
      opacity: 1;
      border-bottom: 3px solid #608bd2;
    }
  }

  .main-content {
    width: 1266px;
    margin: 0 auto;
    background-color: transparent;

    .banner {
      img {
        width: 1266px;
        height: 499px;
        margin-bottom: 0px;
      }
    }

    .content-wrap {
      width: 1229px;
      margin: 0 auto;

      .ggl-content {
        margin-bottom: 70px;

        .title {
          width: 263px;
          height: 61px;
          border-radius: 0px 0px 0px 0px;
          opacity: 1;
          font-size: 24px;
          font-weight: 400;
          color: #1c4363;
          background: url("../../assets/image/homeImg/gongaolanBg.png") no-repeat;
          background-size: 100% 100%;
          margin-bottom: 10px;
          text-align: center;
          line-height: 61px;
        }

        .splitRow {
          height: 2px;
          background: #ffbc4b;
          margin-bottom: 6px;
        }

        .splitRow1 {
          width: 1230px;
        }

        .splitRow2 {
          width: 1212px;
        }

        .ggl-content-main {
          padding-top: 25px;

          .left-title {
            width: 219px;
            font-size: 22px;
            color: #000000;
            padding-top: 21px;
            font-weight: 400;
            display: flex;

            .titleStr {
              width: 118px;
              height: 29px;
              font-size: 22px;
              font-family: Microsoft YaHei-Regular, Microsoft YaHei;
              font-weight: 400;
              color: #000000;
              padding: 0px 15px;
            }

            div {
              display: flex;
              justify-content: center;
              align-items: center;
            }

            img {
              width: 34px;
              height: 20px;
            }
          }

          .right-list-wrap {
            width: 1010px;
            height: 274px;
            background: #ffffff;
            box-shadow: 0px 6px 30px 1px rgba(0, 0, 0, 0.12);
            border-radius: 7px 7px 7px 7px;
            opacity: 1;
            padding: 22px 45px;

            .list-ul {
              font-size: 20px;
              font-family: Microsoft YaHei-Regular, Microsoft YaHei;

              li {
                padding-bottom: 22px;
                cursor: pointer;
                .icon {
                  display: inline-block;
                  border-radius: 50%;
                  width: 10px;
                  height: 10px;
                  background: #4774df;
                }

                .list-item {
                  margin-left: 14px;
                }

                .date {
                  float: right;
                }
              }
            }
          }
        }
      }

      .pxbc-content {
        margin-bottom: 60px;

        .header-wrap {
          display: flex;
          width: 100%;
          justify-content: space-between;
        }

        .title {
          width: 263px;
          height: 61px;
          border-radius: 0px 0px 0px 0px;
          opacity: 1;
          font-size: 24px;
          font-weight: 400;
          color: #1c4363;
          background: url("../../assets/image/homeImg/peixunbanciBg.png") no-repeat;
          background-size: 100% 100%;
          margin-bottom: 10px;
          text-align: center;
          line-height: 61px;
        }

        .more-link {
          float: right;
          font-size: 16px;
          color: #4774df;
          display: flex;
          width: 80px;
          justify-content: space-around;
          cursor: pointer;
          div {
            display: flex;
            justify-content: center;
            align-items: center;

            img {
              height: 16px;
              width: 22px;
            }
          }
        }

        .splitRow {
          height: 2px;
          background: #ff6060;
          margin-bottom: 6px;
        }

        .splitRow1 {
          width: 1230px;
        }

        .splitRow2 {
          width: 1212px;
        }

        .ggl-content-main {
          padding-top: 25px;
        }
      }
    }
  }

  header {
    padding: 0px;
  }

  ::v-deep .el-main {
    padding: 0px;
    padding-bottom: 188px;
  }

  ::v-deep .el-dropdown-menu__item {
    font-size: 16px;
    line-height: 20px;
  }
  ::v-deep .el-carousel__container{
    height: 500px;
  }
</style>
