<template>
  <div class="jpkc-content">
    <div class="header-wrap">
      <div class="title">
        <div>精品课程</div>
      </div>
      <div class="more-link" @click="$router.push('/boutiqueCourse')">
        <div>更多</div>
        <div><img src="../../../assets/image/homeImg/right-progress.png" /></div>
      </div>
    </div>
    <div class="splitRow splitRow1"></div>
    <div class="splitRow splitRow2"></div>
    <div class="jpkc-content-main">
      <ul class="list-ul" v-if='recordsData.length>0'>
        <li v-for="(item,index) in recordsData" :key='index' @click='jumpCourseInfo(item)'>
            <div class="course-cover" v-show='!item.courseCover_url'>
              <div class="course-content">
                {{item.courseTitle}}
              </div>
            </div>
            <div class="course-cover" v-show='item.courseCover_url'>
              <img :src="item.courseCover_url" alt="">
            </div>
          <div class="course-title">{{item.courseTitle}}</div>
        </li>
      </ul>
      <emptyData v-else></emptyData>
    </div>
  </div>
</template>

<script>
import emptyData from '@/components/emptyData.vue'
export default {
  name: '',
  components: {
    emptyData
  },
  props: {},
  watch: {},
  data () {
    return {
      recordsData: []
    }
  },
  methods: {
    jumpCourseInfo (item) {
      let courseId = item.id
      if (item.isMultiple == 'Y') {
        this.$router.push(`/childrenChaptersCourse/${courseId}`)
      } else {
        this.$router.push(`/overCourseInfo/${courseId}`)
      }
    },
    getRecordsData () {
      this.$axios.post(this.$apiUrl.listJpkc, {}).then(res => {
        console.log(res)
        let records = res.data.data
        this.recordsData = JSON.parse(JSON.stringify(records))
      })
    }
  },
  mounted () {
    this.getRecordsData()
  },
}
</script>

<style scoped lang="scss">
/* 精品课程 */
.jpkc-content {
  .header-wrap {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }
  .title {
    width: 263px;
    height: 61px;
    border-radius: 0px 0px 0px 0px;
    opacity: 1;
    font-size: 24px;
    font-weight: 400;
    color: #1c4363;
    background: url("../../../assets/image/homeImg/jingpinkechengBg.png") no-repeat;
    background-size: 100% 100%;
    margin-bottom: 10px;
    text-align: center;
    line-height: 61px;
  }
  .more-link {
      float: right;
      font-size: 16px;
      color: #4774df;
      display: flex;
      width: 80px;
      justify-content: space-around;
      cursor: pointer;
      div {
        display: flex;
        justify-content: center;
        align-items: center;
        img {
          height: 16px;
          width: 22px;
        }
      }
    }
  .splitRow {
    height: 2px;
    background: #5fdec2;
    margin-bottom: 5px;
  }
      .splitRow1 {
        width: 1230px;
      }
      .splitRow2 {
       width: 1212px;
      }
  .jpkc-content-main {
    padding-top: 25px;
    .list-ul {
      display: flex;
      flex-wrap: wrap;
      /* align-content: space-between; */
      /* justify-content: space-between; */
      li {
        width: 25%;
        color: #000000;
        margin-bottom: 50px;
        .course-cover {
          cursor: pointer;
          width: 285px;
          height: 190px;
          font-size: 22px;
          font-family: STKaiti-Regular, STKaiti;
          img{
            width: 100%;
            height: 100%;
            border-radius: 10px;
          }
          .course-content {
            background: url("../../../assets/tuanBg.png") no-repeat;
            background-size: 100% 100%;
            width: 285px;
            height: 190px;
            border-radius: 10px;
            display: flex;
            flex-wrap: wrap;
            padding: 0px 10px;
            justify-content: center;
            align-content: center;
            color: #ffff;
          }
        }
        .course-title {
          cursor: pointer;
          padding-top: 20px;
          width: 285px;
          font-size: 20px;
          line-height: 24px;
          font-family: Microsoft YaHei-Regular, Microsoft YaHei;
          color: #000000;
        }
        .course-title:hover{
            color: #25A3FF ;
          }
      }
    }
  }
}
</style>
